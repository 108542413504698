.smallcontainer
{
    display: flex;
    align-items: center;
    justify-content: center;
}

.dospin{
    animation: spin 1s infinite linear;
}

.smallspinner {
    color: #A73B8D;
    font-size: 20px !important;
    text-align: center;
}

.mediumspinner {
    color: #A73B8D;
    font-size: 40px !important;
    text-align: center;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 975px;
    height: 100vh;
}

.spinner {
    animation: spin 1s infinite linear;
    color: #A73B8D;
    font-size: 100px !important;
}

@keyframes spin {
    100% {
        transform:  rotate(360deg);
    }
}
