
  


.search {
    z-index: 80;
    overflow: visible;
    position: absolute;
    width: 0px;
    pointer-events: none;
    transition: width 0.2s, border 0.2s;
    border: none;
    box-sizing: border-box;
    background-color: transparent;
    caret-color: purple;
    
    &.active {
        width: 250px;
        visibility: visible;
    }
}

.iteminactive
{
    height:0;
    width: 0;
    visibility: hidden;
}
    