
.searchresults {
    /* Remove default list styling */
    list-style-type: none;
    padding: 0;
    margin: 0;
    z-index: 80;
    overflow: visible;
    position: absolute;
    width: 0px;
    pointer-events: none;
    transition: width 0.2s, border 0.2s;
  }
  
  .searchresults li a {
    
    &.active{
    border: 1px solid #ddd; /* Add a border to all links */
    margin-top: -1px; /* Prevent double borders */
    background-color: #f6f6f6; /* Grey background color */
    padding: 12px; /* Add some padding */
    text-decoration: none; /* Remove default text underline */
    font-size: 18px; /* Increase the font-size */
    color: black; /* Add a black text color */
    display: block; /* Make it into a block element to fill the whole list */
    }
  }
  
  .searchresults li a:hover:not(.header) {
    background-color: #eee; /* Add a hover effect to all links, except for headers */
  }

  .allitems .item{
    animation: FadeIn 0.2s steps(90) forwards;
    animation-fill-mode: forwards;
  }

  .Paginationbtn{
    color: rgb(116, 116, 116) !important;
    border-width: 1px !important;
    border-color: #E3E3E3 !important;
    background-color: transparent !important;
    padding: 6px 12px !important;
  }

  .filterbtn{
    color: black !important;
    border: 1px solid #E3E3E3 !important;
    background-color: transparent !important;
    padding: 6px 12px !important;
    &:focus{
      border: 1px solid #AAAAAA !important;
    }
  }

  .filterinput{
    padding: 6px 12px !important;
    height: unset !important;
    font-family: FontAwesome, Arial !important;
  }


  .filtergroup {
   display:flex !important;
  }

  @keyframes FadeOut{
    0% {
      opacity: 1;
      transform: scale(1);
    }
    15% {
      transform: scale(1.05);
    }
    100% {
      opacity: 0;
      transform: scale(.1);
    }
  
  }
  @keyframes FadeIn { 
    0% {
      opacity: 0;
      transform: scale(.1);
    }
  
    85% {
      opacity: 1;
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

.search {
    z-index: 80;
    overflow: visible;
    position: absolute;
    width: 0px;
    pointer-events: none;
    transition: width 0.2s, border 0.2s;
    border: none;
    box-sizing: border-box;
    background-color: transparent;
    caret-color: purple;
    
    &.active {
        width: 250px;
      //  border: solid 1px;
    }
}

.searchitem
{
  position: static;
  opacity: 1;
  transition: position 0.2s, opacity 0.2s linear;
  &.inactive {
    position:absolute;
    opacity: 0;
  }
}


    
.rotate {
    animation: spin 1s infinite linear;
    color: red !important;
}

@keyframes spin {
    100% {
        transform:  rotate(360deg);
    }
}