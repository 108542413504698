
.searchresults {
    /* Remove default list styling */
    list-style-type: none;
    padding: 0;
    margin: 0;
    z-index: 80;
    overflow: visible;
    position: absolute;
    width: 0px;
    pointer-events: none;
    transition: width 0.2s, border 0.2s;
  }
  
  .searchresults li a {
    
    &.active{
    border: 1px solid #ddd; /* Add a border to all links */
    margin-top: -1px; /* Prevent double borders */
    background-color: #f6f6f6; /* Grey background color */
    padding: 12px; /* Add some padding */
    text-decoration: none; /* Remove default text underline */
    font-size: 18px; /* Increase the font-size */
    color: black; /* Add a black text color */
    display: block; /* Make it into a block element to fill the whole list */
    }
  }
  
  .searchresults li a:hover:not(.header) {
    background-color: #eee; /* Add a hover effect to all links, except for headers */
  }

.search {
    z-index: 80;
    overflow: visible;
    position: absolute;
    width: 0px;
    pointer-events: none;
    transition: width 0.2s, border 0.2s;
    border: none;
    box-sizing: border-box;
    
    &.active {
        width: 250px;
        border: solid 1px;
        visibility: visible;

    }
}

.iteminactive
{
    height:0;
    width: 0;
    visibility: hidden;
}
    
.rotate {
    animation: spin 1s infinite linear;
    color: red !important;
}

@keyframes spin {
    100% {
        transform:  rotate(360deg);
    }
}